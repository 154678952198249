/* Home profile container */
.profile_cont {
    width: 100%;
    height: 610px;
    margin-bottom: 60px;
}

.home_back_img {
    width: 100%;
    height: 600px;
}

.navbar {
    position: absolute;
    top: 30px;
    right: 0px;
    left: 0px;
    z-index: 3;
}

.pro_txt_cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 15px;

}

.pro_button_cont {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 7px;
}

.pro_button_cont button {
    width: 162px;
    margin: 0px 20px;
    border-radius: 25px;
    border: none;
    padding: 13px 35px;
    font-weight: 700;
    font-size: 14px;
    color: white;
}


.pro_button_cont button:first-child {
    background-color: #24263b !important;
    border: 2px solid;
}

.pro_button_cont button:first-child:hover {
    border: 2px solid #ff5823;
}

.pro_button_cont button:last-child:hover {
    background-color: #ff5823 !important;
    color: #24263b;
}

.pro_button_cont button:last-child {
    background-color: #ff5823 !important;
    color: #24263b;
}

.profile_button_cont {
    margin-top: 22px !important;
}

.profile_button_cont button a {
    text-decoration: none;
    color: #24263b
}

.pro_icon_cont .icon {
    font-size: 20px;
    padding: 0px;
    margin: 2px;
    border-radius: 20px;
    color: white;
}

.pro_icon_cont .icon_tiw {
    padding: 1px;
}

.pro_icon_cont .icon_you {
    font-size: 33px;
}

.pro_img_cont {
    box-shadow: 0 1px 0 0 white;
    width: 316px;
    height: 310px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    margin-left: 15px;
}

.pro_img_cont img {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    object-fit: revert;
}

.pro_main_cont {
    display: flex;
    width: 70%;
    justify-content: space-between;
}

.pro_container {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 150px;
    left: 20px;
    right: 20px;
    color: white;
}

.pro_name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.pro_name p {
    font-size: 25px;
    font-weight: 500;
}

.pro_name p:last-child {
    font-size: 32px;
    font-weight: 300;
}

.pro_name p span {
    color: #ff5823;

}

.pro_desc p {
    font-size: 21px;
    margin-top: 2px;
}

/* title- about me */
.aboutme_title_container {
    display: flex;
    justify-content: center;
}

.aboutme_main_title_container p {
    font-size: 34px;
    font-weight: bold;
    text-align: center;
}

.aboutme_main_title_container p:nth-child(2) {
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 3px;
    margin-top: -13px;
}

.line_container {
    position: relative;
    display: flex;
    justify-content: center;
}

.line_container .line {
    height: 1.5px;
    width: 161px;
    background-color: #24263b;
}

.line_container .line_center_design {
    position: absolute;
    top: -4px;
    width: 40px;
    height: 9px;
    background-color: #ff5823;
    border-radius: 10px;
}

/* about me */
.abt_main_cont {
    display: flex;
    justify-content: center;
    margin-bottom: 115px;
}

.abt_cont {
    margin-top: 42px;
    width: 65%;
    display: flex;
    box-shadow: 1px 1px 13px 4px #a7a5a5;
}

.abt_img img {
    width: 350px;
}

.abt_discription_main_cont {
    display: flex;
    justify-content: center;
    align-items: center;
}

.abt_discription_cont {
    display: flex;
    flex-direction: column;
    padding: 14px 50px;
    align-items: flex-start;
    width: 100%;
    background-color: white;
    align-content: flex-start;
    z-index: 3;
}

.highlights_cont {
    margin-top: 20px;
}

.highlights_cont p {
    font-size: 23px;
    font-weight: 500;
}

.highlights_cont ul li {
    list-style: none;
}

.highlights_cont ul li::before {
    content: ".";
    color: #ff5823;
    font-weight: bold;
    font-size: 60px;
    position: relative;
    top: 0;
    right: 7px;
    line-height: 5px;
}

.abt_button_cont {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}

.abt_button_cont a:first-child {
    width: 162px;
    margin: 0px 20px;
    border-radius: 25px;
    padding: 13px 0px;
    font-weight: 700;
    font-size: 14px;
    background-color: #24263b;
    color: white !important;
    text-align: center;
    outline: 3px solid white;
}

.abt_button_cont a:first-child:hover {
    outline: 3px solid #ff5823;
}

.abt_button_cont a:first-child button {
    border: none;
    background-color: transparent;
    font-weight: 700;
    color: white;
    width: 160px;
}

.abt_button_cont a:last-child {
    width: 162px;
    margin: 0px 20px;
    border-radius: 25px;
    border: none;
    padding: 13px 0;
    font-weight: 700;
    font-size: 14px;
    background-color: #ff5823;
    color: white;
}

.abt_button_cont a:last-child button {
    border: none;
    background-color: transparent;
    font-weight: 700;
    width: 160px;
    text-align: center;
}
/* why-choose-me */
.whyChooseMe{
    width: 100% !important;
}
.whyChooseMe a:first-child {
    width: 90px;
    margin: 0px 20px;
    border-radius: 25px;
    padding: 6px 0px;
    font-weight: 700;
    font-size: 12px;
    background-color: #24263b;
    color: white !important;
    text-align: center;
    outline: 3px solid white;
}
.whyChooseMe a:last-child {
    width:90px;
    margin: 0px 20px;
    border-radius: 25px;
    padding:6.5px 0px;
    font-weight: 700;
    font-size: 12px;
    background-color: #ff5823;
    color: white !important;
    text-align: center;
    outline: 3px solid white;
}
.whyChooseMe a:first-child button {
    border: none;
    background-color: transparent;
    font-weight: 700;
    width: 90px;
    text-align: center;
}
.whyChooseMe a:last-child button {
    border: none;
    background-color: transparent;
    font-weight: 700;
    width: 91px;
    text-align: center;
}

/* education */
.edu_cont {
    display: flex;
    margin-left: 17.5%;
    margin-right: 50px;
}

.edu_content {
    margin-left: 90px;
    width: 100%;
}

.edu_main_cont {
    display: flex;
    margin-bottom: 60px;
}

.edu_title {
    background-color: #24263b;
    display: flex;
    color: white;
    height: 27px;
    padding: 11px 90px 38px 16px;
    border-radius: 8px;
    border-radius: 1px 25px 25px 0px;
}

.edu_title .edu_icon {
    margin-top: 4px;
}


.edu_title p {
    font-size: 18px;
    margin-left: 10px;
    font-weight: 500;
}

.edu_content table,
tr,
td {
    width: 100%;
}

.edu_content table tr .edu_td {
    padding-left: 50px;
}

.edu_content table tr:first-child td:first-child {

    color: #ff5823;
    font-size: 19px;
    font-weight: 700;
}

.edu_content table {
    border-collapse: separate;
    border-spacing: 0 18px;
    margin-top: -221px;
}

.edu_content table tr {
    border-collapse: separate;
    border-spacing: 0px 20px;
}

.edu_content table tr td button {
    width: 113px;
    border: none;
    background-color: #ff5823;
    border-radius: 25px;
    font-size: 15px;
    padding: 7px 0px;
    font-weight: 500;
    color: white;
}

.edu_content table:before {
    content: ".";
    font-size: 120px;
    color: #ff5823;
    position: relative;
    top: 79px;
    right: 38px;
}

.edu_top {
    position: relative;
    top: -16px;
    font-weight: 500;
}

.edu_top1 {
    position: relative;
    top: -25px;
}

/* skills */
.skill_cont {
    display: flex;
    justify-content: center;
    margin-top: 62px;
}

.skill_main_cont {
    width: 100%;
    display: flex;
    margin-left: 17.5%;
}

.skill_title {
    background-color: #24263b;
    display: flex;
    color: white;
    font-size: 17px;
    font-weight: 600;
    height: 27px;
    padding: 11px 30px 36px 16px;
    border-radius: 8px;
    border-radius: 1px 25px 25px 0px;
}

.skill_icon {
    margin-top: 4px;
    margin-right: 14px;
}

.skil_content_cont {
    width: 200px;
    height: 20px;
    display: flex;
}

.skil_content__main_cont {
    width: 200px;
    margin-top: -72px;
}

.skil_content__main_cont p {
    font-size: 19px;
    font-weight: bold;
    position: relative;
    left: -23px;
    top: 34px;
}

.skil_content__main_cont p:before {
    content: ".";
    font-size: 84px;
    color: #ff5823;
    position: relative;
    left: -14px;
}

.s_rem1 {
    width: 80%;
    background-color: #24263b;
}

.reactjs1 {
    width: 85%;
}

.reactjs2 {
    width: 15% !important;
}

.nodejs1 {
    width: 70%;
}

.nodejs2 {
    width: 30% !important;
}

.s_rem2 {
    width: 20%;
    background-color: #ff5823;
}

.skill_cont_1 {
    position: relative;
    top: -100px;
    left: 88px;
}

.skill_main_cont_2 {
    width: 100%;
    display: flex;
    margin-top: 58px;
}

.skill_main_cont_2 .skill_one {
    margin-right: 68px;
}

/* project */

.porject_cont {

    display: flex;
    justify-content: center;
    margin-left: 0;
    margin-right: 0;
    margin-top: 69px;
}

.project_edu_top {
    font-weight: bold;
}

.project_buttons {
    margin-top: -30px;
}

.project_buttons button {
    background-color: #24263b !important;
    padding: 4px 10px !important;
    font-size: 14px !important;
    cursor: pointer;
}

.project_buttons a:last-child button {
    margin-left: 10px;
}

.project_content table {
    margin-top: -187px !important;
}

/* contact  */
.contact_cont {
    display: flex;
    justify-content: center;
    width: 100%;
}

.contact_main_cont {
    width: 85%;
    height: 500px;
    display: flex;
    background-color: #24263b;
    margin-top: 70px;
}

.contact_cont_1 {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.contact_cont_1 p {
    color: white;
    font-size: 32px;
    font-weight: bold;
}

.contact_cont_2 {
    width: 100%;
    padding: 10px;
}

.getIntoImg {
    width: 100%;
    padding: 0px 0px 0px 10px;
    margin-top: 60px;
}

/* footer */

.footer {
    text-align: center;
    display: flex;

    justify-content: center;
    align-items: center;
    background-color: #24263b;
    color: white;
    height: 50px;
    width: 100%;
    margin-top: 90px;
    padding-top: 14px;

}

/* responsive for 991px */

@media(max-width:992px) {

    /* Home profile container - res */
    .profile_cont {
        height: 800px;
    }

    .pro_main_cont {
        align-items: center;
        flex-direction: column-reverse;
        width: 95%;
    }

    .pro_desc {
        text-align: center;
    }

    .pro_desc p {
        font-size: 19px;
        margin-top: 2px;
    }

    .pro_img_cont img {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        object-fit: revert;
    }

    .pro_img_cont {
        width: 227px;
        height: 214px;
        margin-left: 0px;
    }

    .pro_name p {
        font-size: 22px;
    }

    .pro_name {
        width: 97vw;
        margin-left: 3vw;
    }

    .abt_button_cont {
        width: 92%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 40px;
    }

    .home_back_img {
        width: 100%;
        height: 800px;
    }

    /* about me -res */
    .abt_cont {
        width: 85%;
    }

    .abt_main_cont {
        display: flex;
        justify-content: center;
        margin-bottom: 73px;
    }

    .abt_img {
        display: none;
    }

    .abt_discription_cont p {
        width: 100%;
    }

    .abt_discription_cont {
        padding: 20px 20px;
    }

    .abt_discription_main_cont {
        width: 100%;
    }

    .highlights_cont ul li {
        font-size: 14px;
    }

    /* res-education */
    .edu_cont {
        display: flex;
        margin-left: 7.5%;
        margin-right: 50px;
        margin-bottom: -6px;
    }

    .edu_main_cont {
        display: flex;
        flex-direction: column;
    }

    .edu_content {
        margin-left: 23px;
        width: 100%;
    }

    .edu_content table:before {
        content: ".";
        font-size: 101px;
        color: #ff5823;
        position: relative;
        top: 72px;
        right: 28px;
    }

    .edu_content table {
        border-collapse: separate;
        border-spacing: 0 18px;
        margin-top: -162px;
    }

    .edu_table {
        margin-top: -205px !important;
    }

    .edu_content table tr td button {
        width: 84px;
        border: none;
        background-color: #ff5823;
        border-radius: 25px;
        font-size: 12px;
        padding: 4px 0px;
        font-weight: 500;
        color: white;
        position: relative;
        top: -5px;
        left: 10px;
    }

    .edu_content table tr:first-child td:first-child {
        color: #ff5823;
        font-size: 15px;
        font-weight: 700;
    }

    .edu_content table tr .edu_td {
        padding-left: 0px;
    }

    .edu_top {
        position: relative;
        top: -16px;
        font-size: 13px;
    }

    .edu_top1 {
        position: relative;
        top: -28px;
        font-size: 13px;
    }

    /* res-skills */

    .skill_main_cont {
        flex-direction: column;
        margin-left: 17.5%;
    }

    .skill_cont {
        display: flex;
        justify-content: center;
        margin-top: 62px;
        overflow: hidden;
    }

    .skil_content_cont {
        width: 120px !important;
        height: 16px !important;
        display: flex;
    }

    .skill_title {
        background-color: #24263b;
        display: flex;
        color: white;
        font-size: 17px;
        font-weight: 600;
        height: 27px;
        width: 345px;
        padding: 11px 30px 36px 16px;
        border-radius: 8px;
        border-radius: 1px 25px 25px 0px;
    }

    .skill_main_cont {
        flex-direction: column;
        margin-left: 7.5%;
    }

    .skill_main_cont_2 {
        width: 100%;
        display: flex;
        margin-top: 98px;
        margin-left: -55px;
    }

    .skil_content_cont {
        width: 150px;
        height: 20px;
        display: flex;
    }

    .skill_main_cont_2 .skill_one {
        margin-right: 15px;
    }

    .skil_content__main_cont p {
        font-size: 16px;
        font-weight: bold;
        position: relative;
        left: -23px;
        top: 34px;
    }

    .skil_content_cont {
        width: 150px;
        height: 16px !important;
        display: flex;
    }

    .skil_content__main_cont p:before {
        content: ".";
        font-size: 75px;
        color: #ff5823;
        position: relative;
        left: -14px;
    }

    .skil_content__main_cont {
        width: 150px;
        margin-top: -72px;
    }

    /* res- projects */

    .project_td {
        position: relative;
        top: 9px;
        font-size: 12px !important;
    }

    .porject_cont {
        margin-top: 50px !important;
    }

    .project_td:nth-child(7) {
        top: 10px;
    }

    .project_content table:nth-child(7) .project_td {
        position: relative;
        top: -3px;
    }

    /* res- contact */

    .contact_main_cont {
        flex-direction: column;
    }

    .getIntoImg {
        display: none;
        width: 100%;
        padding: 0px 0px 0px 0;
        margin-top: 10px;
    }

    .contact_main_cont {
        width: 100%;
        height: 592px;
        display: flex;
        background-color: #24263b;
        margin-top: 46px;
    }

    .contact_cont_2 {
        width: 100%;
        padding: 0px;
        margin-top: 17px;
    }

    .contact_cont_1 {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
    }

    .cont_icon {
        margin-top: -12px;
    }

    /* res-footer */

    .footer {
        font-size: 13px;
        padding: 17px;
        margin-top: 0px;
    }

    .footer p {
        padding: 21px 0px 1px 0px;
    }

}

@media(max-width:425px) {
    .abt_button_cont a:first-child {
        width: 154px;
        margin: 0px 20px;
        padding: 7px 0;
        font-weight: 700;
        font-size: 14px;
    }

    .abt_button_cont a:last-child {
        width: 154px;
        margin: 0px 20px;
        padding: 9px 0;
        font-weight: 700;
        font-size: 14px;
    }
    .whyChooseMe a:last-child {
        width: 154px;
        margin: 0px 20px;
        padding: 7px 0;
        font-weight: 700;
        font-size: 14px;
    }

    .abt_button_cont {
        margin-top: 13px;
    }
}

@media(max-width:375px) {
    .abt_button_cont a:first-child {
        width: 130px;
        margin: 0px 20px;
        padding: 7px 0;
        font-weight: 700;
        font-size: 14px;
    }

    .abt_button_cont a:last-child {
        width: 130px;
        margin: 0px 20px;
        padding: 9px 0;
        font-weight: 700;
        font-size: 14px;
    }

    .abt_button_cont {
        margin-top: 13px;
    }

    .abt_button_cont a:first-child button,
    .abt_button_cont a:last-child button {
        width: 130px;
    }
}